import React, { useState } from "react";
import styles from "./Login.module.css";
import { useForm } from "react-hook-form";
import { usePostDataMutation } from "../redux/services/apiService";
import { setUserInfo } from "../redux/features/authSlice";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../components/LoadingSpinner";
import loginBgImage from "../assets/login-bg.png";
import Close from "../components/Close";

const Login = () => {
  const [trigger, { isLoading }] = usePostDataMutation();
  const [errorMessage, setErrorMessage] = useState("");
  const [logginIn, setLogginIn] = useState(false);
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    trigger({
      ...data,
      domain: window.location.hostname,
    })
      .then((res) => {
        setLogginIn(true);
        dispatch(setUserInfo(res));
        if (res?.data?.code === 0) {
          if (res?.data?.apiResponse?.ssokey) {
            window.location.href = `https://members.idsecureus.com/?ocx=${res?.data?.apiResponse?.ssokey}`;
          } else if (
            res?.data?.apiResponse?.last_order?.data?.code === -1 ||
            !res?.data?.apiResponse?.last_order?.data?.ssokey
          ) {
            setErrorMessage(res?.data?.apiResponse?.last_order?.data?.message);
            setLogginIn(false);
          }
        } else if (res?.error?.data?.code === -1) {
          setLogginIn(false);
          setErrorMessage(res?.error?.data?.message);
        } else if (res?.error) {
          setLogginIn(false);
          setErrorMessage("Something went wrong!");
        }
      })
      .catch((e) => {
        setLogginIn(false);
        setErrorMessage("Something went wrong!");
      });
  };

  return (
    <div class="grid grid-cols-2">
      <div className="relative flex items-center justify-center">
        <img className={styles["login-bg"]} src={loginBgImage} alt="bg" />
      </div>
      <div className="flex justify-center items-center">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-[532px]">
            <p className={`${styles["login-header"]} mb-5`}>Member Login</p>
            <div className="py-6">
              <p className={`${styles.label} py-1`}>Username</p>
              <input
                className={`${styles["inputField"]} w-full`}
                placeholder="Enter Username"
                type="text"
                id="username"
                name="username"
                {...register("username", {
                  required: {
                    value: true,
                    message: "Username Required!",
                  },
                })}
              />
            </div>
            <div className="py-6">
              <p className={`${styles.label} py-1`}>Password</p>
              <input
                className={`${styles["inputField"]} w-full`}
                placeholder="Enter Password"
                type="password"
                id="password"
                name="password"
                {...register("password", {
                  required: {
                    value: true,
                    message: "Password Required!",
                  },
                })}
              />
            </div>
            <button className={`${styles["login-btn"]} mt-8`} type="submit">
              {isLoading || logginIn ? <LoadingSpinner /> : "Login"}
            </button>
            {errorMessage !== "" && (
              <div
                className={`bg-transparent border border-blue-300 text-blue-300 px-4 py-3 rounded relative mt-4 ${styles["error-message-bg"]}`}
                role="alert"
              >
                <span
                  className={`${styles["error-message"]} block w-[90%] text-black`}
                >
                  {errorMessage}
                </span>
                <span
                  className="absolute top-0 bottom-0 right-0 px-4 py-3 "
                  onClick={() => setErrorMessage("")}
                >
                  <Close />
                </span>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
